<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">任务名称</label>
        <el-input
          v-model="searchTitle"
          placeholder="请输入任务名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学校名称</label>
        <el-input
          v-model="schoolName"
          placeholder="请输入学校名称"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集类型</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="shotSubject"
          placeholder="采集类型"
          autocomplete="off"
          size="small"
        >
          <el-option :key="0" label="新生" :value="0"> </el-option>
          <el-option :key="1" label="毕业生" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">是否生成批次</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="isBatch"
          placeholder="是否生成批次"
          autocomplete="off"
          size="small"
        >
          <el-option :key="0" label="未生成" :value="0"> </el-option>
          <el-option :key="1" label="已生成" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          v-model="searchCreateDate"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          v-model="searchCreateDateEnd"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束时间"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          v-if="addButton"
          type="success"
          icon="el-icon-plus"
          @click="handleAdd"
          size="small"
          >新增
        </el-button>
        <el-button
          v-if="deleteButton"
          type="danger"
          icon="el-icon-delete"
          @click="handleDelete"
          size="small"
          >删除
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="taskList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column
          fixed
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="任务名称"
          width="260"
          fixed
        ></el-table-column>
        <el-table-column prop="batchName" label="采集批次" width="250"> </el-table-column>
        <el-table-column prop="isCjm" label="采集类型" width="120" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.shotSubjectName
              }}{{ scope.row.isCjm === 0 ? "非加密" : "加密" }}</span
            >
          </template>
        </el-table-column>
        <!--                <el-table-column prop="schoolName" label="学校" width="180">-->
        <!--                </el-table-column>-->
        <el-table-column
          prop="beginDate"
          :formatter="columnDateFormat"
          label="开始日期"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="结束日期"
          :formatter="columnDateFormat"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :formatter="columnDateFormat"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="shotSubjectName"
          label="拍摄对象"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="任务状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="info" effect="plain" size="small"
              >未开始</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 1"
              type="success"
              size="small"
              effect="plain"
              >预约中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 2"
              type="warning"
              size="small"
              effect="plain"
              >已结束</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small">已取消</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="appointmentType"
          label="是否预约"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.appointmentType === 0">否</span>
            <span v-else-if="scope.row.appointmentType === 1">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="任务编号" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="subscribeNumSum"
          align="center"
          label="采集进度"
          width="240"
        >
          <template slot-scope="scope">
            未采集:{{ scope.row.educationsNotPaySum }}&nbsp;&nbsp;&nbsp;&nbsp;已预约 :{{
              scope.row.alreadySubscribeNumSum
            }}
            <br />
            已采集:{{ scope.row.completePayNumSum }}&nbsp;&nbsp;&nbsp;&nbsp;总人数:{{
              scope.row.educationsSum
            }}
          </template>
        </el-table-column>
        <el-table-column prop="content" label="备注" width="260" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-button
              v-if="updateButton"
              type="text"
              v-show="scope.row.status === 0 || scope.row.status === 3"
              @click="handleUpdate(scope.row.id)"
              >修改
            </el-button>
            <el-button v-if="viewButton" type="text" @click="handleView(scope.row.id)"
              >查看
            </el-button>
            <el-button
              v-if="taskTimeButton"
              type="text"
              v-show="scope.row.status == 0 && scope.row.appointmentType == 1"
              @click="
                handleTaskTime(scope.row.id, scope.row.beginDate, scope.row.endDate)
              "
              >时段管理
            </el-button>
            <!-- <el-button
              v-if="photographerButton"
              type="text"
              v-show="scope.row.status != 2"
              @click="handleTaskGrapher(scope.row.id)"
              >任务分配
            </el-button> -->
            <el-button
              v-if="enableButton"
              type="text"
              v-show="scope.row.status === 0"
              @click="handleEnable(scope.row.id)"
              >开始预约
            </el-button>
            <el-button
              v-if="finishedButton"
              type="text"
              v-show="scope.row.status != 2"
              @click="handleFinished(scope.row.id)"
              >结束任务
            </el-button>
            <el-button
              v-if="cencelButton"
              type="text"
              v-show="scope.row.status === 0 || scope.row.status === 1"
              @click="handleCencel(scope.row.id)"
              >取消任务
            </el-button>
            <el-button
              v-if="downloadXpButton"
              type="text"
              v-show="scope.row.status === 1"
              @click="handleTimes(scope.row.id)"
              >修改采集时间
            </el-button>
            <el-button
              v-if="downloadXpButton"
              type="text"
              v-show="scope.row.status != 0"
              @click="handleDownloadXp(scope.row)"
              >打包下载
            </el-button>
            <!-- <el-button
              v-if="downloadXpFailButton"
              type="text"
              v-show="scope.row.status != 0"
              @click="handleDownloadXpFail(scope.row.id)"
              >失败名单
            </el-button> -->
            <el-button
              v-if="viewStudentButton"
              type="text"
              @click="handleViewStudent(scope.row.id)"
              >学生信息
            </el-button>
            <el-button
              v-if="isUploadInfo"
              type="text"
              v-show="
                (scope.row.status === 0 ||
                  scope.row.status === 1 ||
                  scope.row.status === 3) &&
                scope.row.isCjm == 0
              "
              @click="handleViewStuUpload(scope.row.id)"
              >上传学生信息
            </el-button>
            <!-- v-show="scope.row.isRefund === 0" -->

            <el-button
              v-if="viewButton"
              type="text"
              v-show="scope.row.isRefund == 0"
              @click="handleRefund(scope.row.id)"
              >查看退款详情
            </el-button>
            <el-button
              v-if="batchUploadButton"
              type="text"
              v-show="scope.row.status === 1"
              @click="batchUpload(scope.row.id)"
              >批量上传
            </el-button>
            <el-button
              v-if="cjmUploadButton"
              type="text"
              v-show="
                scope.row.shotSubjectName == '毕业生' &&
                scope.row.isCjm == 0 &&
                scope.row.isCompleteUploadCjm == 0
              "
              @click="cjmUpload(scope.row.id)"
              >上传采集码
            </el-button>
            <el-button
              type="text"
              v-if="ViewrecordButton"
              @click="handleViewrecord(scope.row.id)"
              >查看导入进度</el-button
            >
            <el-button
              v-if="pushReminderButton"
              type="text"
              v-show="scope.row.status == 1"
              @click="pushReminder(scope.row.id)"
              >采集提醒推送</el-button
            >
            <el-button
              v-if="adultEducationButton"
              type="text"
              v-show="scope.row.isAdult == 1 && scope.row.status == 2"
              @click="adultEducation(scope.row.id)"
              >上传成教/分院信息</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增任务 -->
    <el-dialog
      :title="
        dialogType === 1 ? '新增采集计划' : dialogType === 2 ? '查看任务' : '修改任务'
      "
      :visible.sync="dialogVisible"
      width="1040px"
      center="center"
    >
      <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
        <el-form ref="taskForm" :model="taskForm" label-width="180px" :rules="taskRules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="采集类型" prop="appointmentType">
                <el-radio-group
                  v-model="taskForm.appointmentType"
                  @change="handleType"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">正常预约校拍</el-radio>
                  <el-radio :label="0">免预约校拍</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="采集对象" prop="shotSubject">
                <el-radio-group
                  @change="handleSubject"
                  v-model="taskForm.shotSubject"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">毕业生</el-radio>
                  <el-radio :label="0">新生</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择学校" prop="schoolId">
                <el-select
                  style="width: 280px"
                  v-model="taskForm.schoolId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入学校名或代码检索"
                  :disabled="dialogType === 2 || dialogType === 3"
                  :remote-method="remoteMethod"
                  @change="selectSchool"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in schools"
                    :key="item.id"
                    :label="item.schoolFilterName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任务名称" prop="title" style="width: 100%">
                <el-input
                  v-model="taskForm.title"
                  style="width: 280px"
                  type="text"
                  placeholder="请输入任务名称"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人" prop="contactPerson">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.contactPerson"
                  placeholder="请输入联系人"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contactTell">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.contactTell"
                  placeholder="请输入联系电话"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="开始日期" prop="beginDate">
                <el-date-picker
                  style="width: 280px"
                  v-model="taskForm.beginDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始日期"
                  :disabled="dialogType === 2"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束日期" prop="endDate">
                <el-date-picker
                  style="width: 280px"
                  v-model="taskForm.endDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择结束日期"
                  :disabled="dialogType === 2"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="收费标准" prop="shotPrice">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.shotPrice"
                  placeholder="请输入收费标准"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 查看摄影师 -->
            <el-col :span="12" v-if="dialogType === 2">
              <el-form-item label="摄影师" prop="photographer">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.photographer"
                  placeholder="请选择摄影师"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>

            <!-- 编辑摄影师 -->
            <el-col :span="12" v-if="dialogType === 1 || dialogType === 3">
              <el-form-item label="摄影师" prop="photographer">
                <el-select
                  style="width: 280px"
                  v-model="taskForm.photographer"
                  multiple
                  placeholder="请选择摄影师"
                  :disabled="dialogType === 2"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in photographers"
                    :key="item.photographerName"
                    :label="item.photographerName"
                    :value="item.photographerName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="是否先上传采集码"
                :prop="taskForm.shotSubject == 1 ? 'isCjm' : ''"
                v-show="taskForm.shotSubject == 1"
              >
                <el-radio-group
                  v-model="taskForm.isCjm"
                  :disabled="
                    dialogType === 2 || dialogType === 3 || taskForm.shotSubject == 0
                  "
                >
                  <el-radio :label="1">提供</el-radio>
                  <el-radio :label="0">不提供</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="dialogType === 2 || dialogType === 3">
              <el-form-item label="任务代码" prop="taskCode">
                <el-input style="width: 80%" disabled v-model="taskForm.taskCode">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否导入成教分院信息" prop="isAdult">
                <el-radio-group
                  v-model="taskForm.isAdult"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="支付类型" prop="payType">
                <el-radio-group v-model="taskForm.payType" :disabled="dialogType === 2">
                  <el-radio :label="1" :disabled="taskForm.appointmentType === 0"
                    >工行支付</el-radio
                  >
                  <el-radio :label="2" :disabled="taskForm.appointmentType === 0"
                    >微信支付</el-radio
                  >
                  <el-radio :label="0">现场扫码</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="是否加急" prop="urgentType">
                <el-radio-group
                  v-model="taskForm.urgentType"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="备用支付是否自动退款" prop="isRefund">
                <el-radio-group
                  v-model="taskForm.isRefund"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="毕业时间" prop="graduationYear">
                <el-date-picker
                  style="width: 280px"
                  v-model="taskForm.graduationYear"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始日期"
                  :disabled="dialogType === 2"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="dialogType === 2 || dialogType === 3">
              <!-- 编辑学历 -->
              <el-form-item label="学历" prop="educationType">
                <el-input
                  style="width: 280px"
                  type="text"
                  v-model="taskForm.educationType"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 编辑学历 -->
            <el-col :span="12" v-if="dialogType === 1">
              <el-form-item label="学历层次" prop="educationType">
                <el-select
                  style="width: 280px"
                  v-model="taskForm.educationType"
                  multiple
                  placeholder="请选择学历"
                  :disabled="dialogType === 2 || dialogType === 3"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in educationTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否提供对比报告" prop="isReport">
                <el-radio-group
                  v-model="taskForm.isReport"
                  :disabled="dialogType === 2 || dialogType === 3"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row v-show="dialogType != 2">
            <el-col :span="12">
              <el-form-item label="任务时段">
                <el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="
                    handleTaskTime(
                      taskForm.id,
                      taskForm.beginDate,
                      taskForm.endDate
                    )
                  "
                  >任务时段管理
                </el-button>
              </el-form-item>
            </el-col> -->
          <!-- <el-col :span="12"></el-col> -->
          <!-- </el-row> -->

          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="content">
                <el-input
                  style="width: 95%"
                  :disabled="dialogType === 2"
                  v-model="taskForm.content"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form
          :model="fileForm"
          ref="fileForm"
          v-show="dialogType != 2"
          label-width="150px"
          @submit.native.prevent
        >
          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="上传学生信息表：" prop="file">
                <el-upload
                  class="upload-demo"
                  ref="uploadImport"
                  :multiple="false"
                  action=""
                  :limit="1"
                  :on-change="handleChange"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  accept=".xls,.xlsx"
                  :auto-upload="false"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >选取文件
                  </el-button>
                  <div slot="tip" class="el-upload__tip">
                    只能上传xls/xlsx文件，且不超过10M
                    <a :href="'/temp/educationTemp.xls?ver=' + times"
                      >下载模板</a
                    >
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row v-if="msgVisible">
            <el-col :span="24">
              <el-form-item label="日志" prop="importMsg">
                <el-alert
                  :visible.sync="msgVisible"
                  v-model="importMsg"
                  :title="importMsg"
                  show-icon
                  :type="importSatus"
                >
                </el-alert>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="dialogType !== 2"
          type="primary"
          @click="handleConfirm"
          :loading="confirmLoading"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 修改采集时间 -->
    <el-dialog
      title="修改采集时间"
      :visible.sync="dialogTimesVisible"
      width="800px"
      center="center"
    >
      <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
        <el-form ref="taskForm" :model="taskForm" label-width="120px" :rules="taskRules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="计划标题" prop="title" style="width: 100%">
                <el-input
                  style="width: 100%"
                  type="text"
                  v-model="taskForm.title"
                  placeholder="请输入计划标题"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学校" prop="schoolId">
                <el-select
                  style="width: 100%"
                  v-model="taskForm.schoolId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入学校名或代码检索"
                  :disabled="dialogType === 2 || dialogType === 3"
                  :remote-method="remoteMethod"
                  @change="selectSchool"
                  :loading="loading"
                >
                  <el-option
                    v-for="item in schools"
                    :key="item.id"
                    :label="item.schoolFilterName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人" prop="contactPerson">
                <el-input
                  type="text"
                  v-model="taskForm.contactPerson"
                  placeholder="请输入联系人"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="contactTell">
                <el-input
                  type="text"
                  v-model="taskForm.contactTell"
                  placeholder="请输入联系电话"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="开始日期" prop="beginDate">
                <el-date-picker
                  v-model="taskForm.beginDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择开始日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束日期" prop="endDate">
                <el-date-picker
                  v-model="taskForm.endDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="收费标准" prop="shotPrice">
                <el-input
                  type="text"
                  v-model="taskForm.shotPrice"
                  placeholder="请输入收费标准"
                  :disabled="dialogType === 2 || dialogType === 3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="content" style="margin-bottom: 10px">
                <el-input
                  v-model="taskForm.content"
                  type="textarea"
                  :disabled="dialogType === 2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="支付类型" prop="payType">
                <el-radio-group v-model="taskForm.payType" :disabled="dialogType === 2">
                  <el-radio :label="1">线上支付</el-radio>
                  <el-radio :label="2">微信备用支付</el-radio>
                  <el-radio :label="0">线下支付</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTimesVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTimesConfirm" :loading="confirmLoading"
          >确 定
        </el-button>
      </div>
    </el-dialog>

    <!--    任务时间段设置-->
    <el-dialog
      title="任务时段管理"
      :visible.sync="dlgTimeVisiable"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center="center"
    >
      <div style="max-height: 600px; overflow: hidden; overflow-y: auto">
        <el-form
          class="divForm"
          ref="taskTimeForm"
          :model="taskTimeForm"
          label-width="150px"
          :rules="taskTimeRules"
        >
          <el-calendar v-model="newDate">
            <template slot="dateCell" slot-scope="{ date, data }">
              <div class="divDate" style="height: 100%" @click="selectCalendar(data.day)">
                <div :class="data.isSelected ? 'is-selected' : ''">
                  {{ data.day.split("-").slice(1).join("-") }}
                  {{ data.isSelected ? "✔️" : "" }}
                </div>
                <div v-for="item in Calendar" :key="item">
                  <div
                    v-if="item.shotDate.slice(0, 4).indexOf(data.day.slice(0, 4)) != -1"
                  >
                    <div
                      v-if="
                        item.shotDate
                          .split('-')
                          .slice(1)[0]
                          .indexOf(data.day.split('-').slice(1)[0]) != -1
                      "
                    >
                      <div
                        v-if="
                          item.shotDate
                            .split('-')
                            .slice(2)
                            .join('-')
                            .slice(0, 2)
                            .indexOf(data.day.split('-').slice(2).join('-')) != -1
                        "
                      >
                        <div class="fc-day-grid-event">
                          <div
                            class=""
                            v-if="item.surplusSubscribeNumSum > 0"
                            id="surplus_subcribe"
                          >
                            <p class="day-value">
                              最大预约数:<span class="spanValue">{{
                                item.subscribeNumSum
                              }}</span>
                            </p>
                            <p class="day-value">
                              已预约:<span class="spanValue">{{
                                item.alreadySubscribeNumSum
                              }}</span
                              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;剩余:<span
                                class="spanValue"
                                >{{ item.surplusSubscribeNumSum }}</span
                              >
                            </p>
                            <div
                              style="
                                width: 10px;
                                height: 10px;
                                border-radius: 10px;
                                float: right;
                                margin-right: 2px;
                                margin-top: -50px;
                                background: #0a0;
                              "
                            ></div>
                          </div>
                          <div class="" v-else id="full_subscribe">
                            <p class="day-value">
                              最大预约数:<span class="spanValue">{{
                                item.subscribeNumSum
                              }}</span>
                            </p>
                            <p class="day-value">
                              已预约:<span class="spanValue">{{
                                item.alreadySubscribeNumSum
                              }}</span
                              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;剩余:<span
                                class="spanValue"
                                >{{ item.surplusSubscribeNumSum }}</span
                              >
                            </p>
                            <div
                              style="
                                width: 10px;
                                height: 10px;
                                border-radius: 10px;
                                float: right;
                                margin-right: 2px;
                                margin-top: -50px;
                                background: #aa0000;
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-calendar>
          <div style="width: 100%; text-align: right; padding-bottom: 10px">
            <el-button
              type="success"
              size="mini"
              class="el-icon--right"
              icon="el-icon-circle-plus"
              @click="handleAddTaskTime()"
              >新增时段
            </el-button>
          </div>
          <el-table
            :data="taskTimeList"
            v-loading="tableLoading"
            stripe
            border
            style="width: 100%"
          >
            <el-table-column prop="shotDate" label="拍摄日期">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.shotDate"
                  type="date"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  placeholder="选择拍摄日期"
                  :picker-options="pickerOptions"
                  style="width: 100%"
                >
                </el-date-picker>
              </template>
            </el-table-column>
            <el-table-column prop="beginTime" label="开始时间">
              <template slot-scope="scope">
                <el-time-select
                  placeholder="起始时间"
                  v-model="scope.row.beginTime"
                  :picker-options="{
                    start: '08:00',
                    step: '00:30',
                    end: '18:00',
                  }"
                  style="width: 100%"
                >
                </el-time-select>
              </template>
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间">
              <template slot-scope="scope">
                <el-time-select
                  placeholder="结束时间"
                  v-model="scope.row.endTime"
                  :picker-options="{
                    start: '08:00',
                    step: '00:30',
                    end: '18:00',
                    minTime: scope.row.beginTime,
                  }"
                  style="width: 100%"
                >
                </el-time-select>
              </template>
            </el-table-column>
            <el-table-column prop="subscribeNum" label="预约数量限制">
              <template slot-scope="scope">
                <el-input
                  type="text"
                  v-model="scope.row.subscribeNum"
                  placeholder="请输入预约数量限制"
                  style="width: 100%"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-view"
                  @click="handleDeleteTaskTime(scope.$index)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgTimeVisiable = false">取 消</el-button>
        <el-button type="primary" @click="handleSaveTime" :loading="confirmLoading"
          >保存
        </el-button>
      </div>
    </el-dialog>

    <!--        分配摄影师-->
    <!-- <el-dialog title="任务分配" :visible.sync="dlgDtlVisible" width="900px">
      <el-form
        ref="taskDetailForm"
        :model="taskDetailForm"
        label-width="80px"
        :rules="taskDetailRules"
      >
        <div class="tip">任务信息</div>
        <el-row class="rowGray">
          <el-col class="cell" :span="4">
            <label>任务名称</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="taskDetailForm.title"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>学校名称</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="taskDetailForm.schoolName"></label>
          </el-col>
        </el-row>
        <el-row class="rowBlank">
          <el-col class="cell" :span="4">
            <label>采集类型</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="taskDetailForm.collectTypeName"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>预计采集人数</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="taskDetailForm.educationsSum"></label>
          </el-col>
        </el-row>
        <el-row class="rowGray">
          <el-col class="cell" :span="4">
            <label>任务开始时间</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label>{{ formDateFormat(taskDetailForm.beginDate) }}</label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>任务结束时间</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label>{{ formDateFormat(taskDetailForm.endDate) }}</label>
          </el-col>
        </el-row>
        <el-row class="rowBlank">
          <el-col class="cell" :span="4">
            <label>学生类型</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="taskDetailForm.shotSubjectName"></label>
          </el-col>
          <el-col class="cell" :span="4">
            <label>任务编号</label>
          </el-col>
          <el-col class="cell" :span="8">
            <label v-text="taskDetailForm.code"></label>
          </el-col>
        </el-row>
        <el-row class="rowGray">
          <el-col class="cell" :span="4">
            <label>预约采集时间</label>
          </el-col>
          <el-col class="cell" :span="20">
            <label
              >{{ formDateFormat(taskDetailForm.beginDate) }}至{{
                formDateFormat(taskDetailForm.endDate)
              }}</label
            >
          </el-col>
        </el-row>
        <div class="tip">摄影师分配</div>
        <div class="">
          <el-form-item label="摄影师" prop="photographer">
            <el-input
              type="text"
              v-model="taskDetailForm.photographer"
              placeholder="请输入摄影师"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 30px 0 10px 0; text-align: center"
      >
        <el-button @click="dlgDtlVisible = false" style="margin-right: 30px"
          >取 消
        </el-button>
        <el-button type="primary" @click="saveGrapher">确 定 </el-button>
      </div>
    </el-dialog> -->

    <!--校拍拍照片下载-->
    <el-dialog
      title="校拍拍照片下载"
      :visible.sync="dlgDownloadVisible"
      width="50%"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :rules="downloadRules"
        :model="downloadForm"
        ref="downloadForm"
        label-width="150px"
      >
        <el-form-item label="班级流水号模式" prop="rarFile1">
          <label>
            {{ downloadForm.rarFile1 === null ? "未打包" : downloadForm.rarFile1 }}
          </label>
        </el-form-item>
        <!-- <el-form-item label="身份证号模式文件" prop="rarFile2">
          <label>
            {{
              downloadForm.rarFile2 === null ? "未打包" : downloadForm.rarFile2
            }}
          </label>
        </el-form-item> -->
        <el-form-item label="成教/分院信息文件" prop="rarFile4" v-show="isAdult == 1">
          <label>
            {{ downloadForm.rarFile4 === null ? "未打包" : downloadForm.rarFile4 }}
          </label>
        </el-form-item>
        <!-- <el-form-item label="整体流水号模式文件" prop="rarFile3">
          <label>
            {{
              downloadForm.rarFile3 === null ? "未打包" : downloadForm.rarFile3
            }}
          </label>
        </el-form-item> -->
        <el-form-item label="打包模式" prop="mode">
          <el-radio-group v-model="downloadForm.mode" @change="handleMode">
            <el-radio :label="1">分班级流水号</el-radio>
            <!-- <el-radio :label="2"
              >学号+姓名+身份证号</el-radio
            > -->
            <el-radio :label="4" v-show="isAdult == 1">成教/分院信息打包</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注" prop="importMsg">
          <el-alert
            title="照片较多时，打包耗时较长(大概1000张/1小时)，开始打包后，可以关闭该窗口进行其他操作，过段时间再进行下载。"
            type="info"
          >
          </el-alert>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button
          style="margin-left: 10px"
          type="success"
          @click="handlePackage"
          :loading="packageLoading"
          >照片打包
        </el-button>
        <el-button
          style="margin-left: 10px"
          type="success"
          @click="handleBgeinDownload"
          :loading="confirmDownLoading"
        >
          下载打包文件
        </el-button>
        <el-button @click="dlgDownloadVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!--查看任务下的学生信息-->
    <el-dialog
      title="查看任务学生信息"
      :visible.sync="dlgViewStudentVisible"
      width="95%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div style="display: flex; flex-wrap: wrap">
        <div class="condition-item">
          <label>姓名</label>
          <el-input
            v-model="searchStudentName"
            placeholder="请输入姓名"
            style="width: 150px"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <label>身份证</label>
          <el-input
            v-model="searchStudentIdNumber"
            placeholder="请输入身份证"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <label>学号</label>
          <el-input
            v-model="searchStudentCode"
            placeholder="请输入学号"
            style="width: 200px"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearchStudent"
            size="small"
            >查询
          </el-button>
        </div>
        <div class="condition-item">
          <el-button
            type="success"
            icon="el-icon-s-data"
            :loading="confirmLoading"
            @click="handleExportNotPayXp"
            size="small"
          >
            导出未采集学生
          </el-button>
          <el-button
            type="success"
            icon="el-icon-s-data"
            :loading="confirmLoadingStu"
            @click="handleExportCjm"
            size="small"
          >
            导出未上传采集码学生
          </el-button>
          <el-button
            type="success"
            icon="el-icon-s-data"
            :loading="confirmLoadingMake"
            @click="handleExportNotMake"
            size="small"
          >
            导出未预约学生
          </el-button>
          <!-- <el-button
            type="success"
            icon="el-icon-s-data"
            :loading="confirmLoadingFace"
            @click="handleExportFace"
            size="small"
          >
            下载人脸识别信息
          </el-button> -->
          <el-button
            type="success"
            icon="el-icon-s-data"
            :loading="confirmLoadingStudent"
            @click="handleExportStudent"
            size="small"
          >
            导出已采集学生
          </el-button>
        </div>
      </div>
      <div style="margin-bottom: 20px; flex: 1; overflow: hidden">
        <el-table
          :data="studentList"
          v-loading="tableStudentLoading"
          stripe
          border
          height="440px"
          style="width: 100%"
        >
          <el-table-column prop="orderNo" label="订单号" width="240" align="center">
          </el-table-column>
          <el-table-column prop="studentName" label="姓名" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ execDecrypt(scope.row.studentName, "name") }}</span></template
            >
          </el-table-column>
          <el-table-column prop="mobileNumber" label="手机号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ execDecrypt(scope.row.mobileNumber, "ph") }}</span></template
            >
          </el-table-column>

          <el-table-column prop="idNumber" label="身份证" width="160" align="center">
          </el-table-column>
          <el-table-column prop="schoolCode" label="学校代码" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="学校名称" align="center" width="180">
          </el-table-column>
          <el-table-column prop="studentCode" label="学号" width="120" align="center">
          </el-table-column>

          <el-table-column prop="classes" label="班级" width="150" align="center">
          </el-table-column>
          <el-table-column prop="fy" label="分院" width="150" align="center">
          </el-table-column>
          <el-table-column
            prop="educationType"
            label="学历类别"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="collectedTime"
            :formatter="columnDateFormat"
            label="采集时间"
            width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="cjmn" label="采集码" width="180" align="center">
          </el-table-column>
          <el-table-column prop="payTypeName" label="支付类型" width="150" align="center">
          </el-table-column>
          <el-table-column prop="orderStatus" label="状态" width="80" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.orderStatus === 0" type="warning" effect="plain"
                >下单</el-tag
              >
              <el-tag
                v-else-if="scope.row.orderStatus === 1"
                type="success"
                effect="plain"
                >预付款
              </el-tag>
              <el-tag v-else-if="scope.row.orderStatus === 2" type="" effect="plain"
                >完成</el-tag
              >
              <el-tag v-else-if="scope.row.orderStatus === 3" type="danger" effect="plain"
                >取消</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="display: flex; justify-content: flex-end">
        <el-pagination
          @size-change="handleStudentSizeChange"
          @current-change="handleStudentCurrentChange"
          :current-page="studentCurrentPage"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="studentPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="studentTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 上传学生信息/成教点 -->
    <el-dialog
      :title="UplodTitle"
      :visible.sync="stuUplodShow"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-upload
        class="upload-demo"
        ref="uploadImport"
        :multiple="false"
        action=""
        :limit="1"
        :on-change="handleUploadChange"
        :file-list="[]"
        accept=".xls,.xlsx"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button
          slot="trigger"
          size="small"
          type="primary"
          :loading="stuUpdateLoading"
          :disabled="stuUpdateLoading"
          >{{ stuUpdateLoading ? "文件上传中..." : "选取文件" }}
        </el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传xls/xlsx文件，且不超过10M
          <span v-show="UplodTitle == '上传学生信息'">
            <a :href="'/temp/educationTemp.xls?ver=' + times">下载学生信息模板</a></span
          >
          <span v-show="UplodTitle == '上传成教/分院信息'">
            <a :href="'/temp/adultEducation.xls?ver=' + times"
              >下载成教/分院信息模板</a
            ></span
          >
        </div>
      </el-upload>
    </el-dialog>

    <el-dialog
      title="退款详情"
      :visible.sync="refundLodShow"
      width="1500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- 搜索部分 -->
      <div style="display: flex; flex-wrap: wrap">
        <div class="condition-item">
          <label class="fn-14">订单号</label>
          <el-input
            clearable
            v-model="redSearchOrderNo"
            placeholder="请输入订单号"
            autocomplete="off"
            size="small"
          ></el-input>
        </div>
        <div class="condition-item">
          <label class="fn-14">姓名</label>
          <el-input
            clearable
            v-model="redSearchName"
            placeholder="请输入学生姓名"
            autocomplete="off"
            size="small"
            style="width: 150px"
          ></el-input>
        </div>

        <div class="condition-item">
          <label class="fn-14">身份证号</label>
          <el-input
            clearable
            v-model="redSearchIdNumber"
            placeholder="请输入身份证号"
            autocomplete="off"
            size="small"
            style="width: 180px"
          ></el-input>
        </div>
        <div class="condition-item">
          <el-button
            v-if="searchButton"
            type="primary"
            size="small"
            style="width: 85px"
            @click="handleSearchRed"
            >查询</el-button
          >
          <el-button type="success" size="small" @click="exportRefund"
            >导出退款信息</el-button
          >
        </div>
      </div>
      <el-table
        :data="refundList"
        v-loading="refundLoading"
        stripe
        border
        height="500px"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        @selection-change="handleSelectRefund"
      >
        <template #empty>
          <p>{{ refundLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column align="center" type="selection" width="50"> </el-table-column>
        <el-table-column prop="orderNo" label="订单号" width="300"> </el-table-column>
        <el-table-column prop="realName" label="姓名" width="120" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.realName, "name") }}</span></template
          >
        </el-table-column>
        <el-table-column prop="mobileNumber" label="手机号" width="150" align="center">
        </el-table-column>
        <el-table-column prop="idNumber" label="身份证号" width="200" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
          >
        </el-table-column>
        <el-table-column prop="schoolName" label="学校" width="240" align="center">
        </el-table-column>
        <el-table-column prop="studentCode" label="学号" width="180" align="center">
        </el-table-column>
        <el-table-column prop="major" label="专业" width="160" align="center">
        </el-table-column>
        <el-table-column prop="isRefund" label="退款状态" width="120" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isRefund == 0" type="warning" effect="plain"
              >未退款</el-tag
            >
            <el-tag v-else-if="scope.row.isRefund == 1" type="success" effect="plain"
              >已退款
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="applyTime"
          :formatter="columnDateFormat"
          label="申请时间"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="refundTime"
          :formatter="columnDateFormat"
          label="退款时间"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="bankName" label="开户行" width="260" align="center">
        </el-table-column>

        <el-table-column prop="bankCardNo" label="银行账号" width="260" align="center">
        </el-table-column>
      </el-table>
      <div style="display: flex; justify-content: center; padding-top: 10px">
        <el-pagination
          @size-change="handleSizeChangerefund"
          @current-change="handleCurrentChangerefund"
          :current-page="currentPageRefund"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="pageSizeRefund"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRefund"
        >
        </el-pagination>
      </div>
      <div style="text-align: right">
        <el-button type="text" style="color: #999"
          >合计:<span
            style="font-size: 18px; font-weight: bold; color: #446cf3; padding-left: 10px"
            >{{ refundNum }}人</span
          ></el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="refundConfirm"
          :loading="loading"
          :disabled="loading"
          >确认已退款</el-button
        >
      </div>
    </el-dialog>

    <!-- 批量上传 -->
    <el-dialog
      :title="batchUplodTitle"
      :visible.sync="batchUplodShow"
      width="350px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-upload
        class="upload-demo"
        ref="uploadImport"
        :multiple="false"
        action=""
        :limit="1"
        :on-change="handleUploadBatch"
        :file-list="[]"
        accept=".zip,.rar"
        :auto-upload="false"
        :show-file-list="false"
      >
        <el-button
          style="margin-left: 120px"
          slot="trigger"
          size="small"
          type="primary"
          :loading="batchUpdateLoading"
          :disabled="batchUpdateLoading"
          >{{ batchUpdateLoading ? "文件上传中..." : "选取文件" }}
        </el-button>
        <!-- <div slot="tip" class="el-upload__tip">
          只能上传xls/xlsx文件，且不超过10M
          <a :href="'/temp/educationTemp_XP.zip?ver=' + times">下载校拍模板</a>
          <a
            :href="'/temp/educationTemp_SP.zip?ver=' + times"
            style="margin-left: 10px"
            >下载散拍模板</a
          >
        </div> -->
      </el-upload>
    </el-dialog>
    <!-- 导入进度 -->
    <el-dialog
      title="导入进度"
      :visible.sync="ViewrecordVisible"
      width="1100px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table
        :data="ViewrecordList"
        v-loading="ViewrecordLoading"
        stripe
        border
        height="450"
        style="width: 100%"
      >
        <template #empty>
          <p>{{ ViewrecordLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="uploadType" label="上传类型" width="160" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.uploadType === 0">批量上传</span>
            <span v-if="scope.row.uploadType === 1">采集码上传 </span>
            <span v-if="scope.row.uploadType === 2">成教/分院信息上传</span>
          </template>
        </el-table-column>
        <el-table-column prop="fileRealName" label="文件名称" width="360">
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="导入时间"
          :formatter="columnDateFormat"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="导入状态" width="150" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 0"
              type="primary"
              size="small"
              effect="plain"
              >新建</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 1"
              type="success"
              size="small"
              effect="plain"
              >导入中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 2"
              type="success"
              size="small"
              effect="plain"
              >导入成功</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 3"
              type="danger"
              size="small"
              effect="plain"
              >导入失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="resultMsg" label="导入结果" width="270">
          <template slot-scope="scope">
            <span v-html="scope.row.resultMsg"></span>
          </template>
        </el-table-column>
        <el-table-column prop="createBy" label="操作人"> </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="ViewrecordVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 打包字段选择 -->
    <el-dialog
      title="请选择打包字段"
      :visible.sync="ModeVisible"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div style="margin: 30px 30px 0px 30px">
        <el-checkbox-group v-model="ModeData">
          <el-checkbox
            style="width: 120px; text-align: left; height: 30px"
            v-for="mode in ModeOption"
            :label="mode.value"
            :key="mode.value"
            >{{ mode.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>

      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="defineMode" type="primary" v-show="ModeData.length"
          >确定打包</el-button
        >
        <el-button @click="closeMode">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryTask,
  addTask,
  getTask,
  getTaskDetail,
  updateTask,
  deleteTask,
  getTaskPeriodById,
  setTaskStatus,
  getTaskCode,
  getTaskStatistics,
  batchSave,
  savePhotographer,
  packageXp,
  updateTaskCjTime,
  queryRefunds,
  comfirmRefunds,
  uploadTaskList,
  smsRemind,
  uploadAdult,
} from "@/api/task";
import { dateFormat } from "@/utils/date";
import { getSchoolFiller, getSchool } from "@/api/school";
import { queryParam } from "../../../api/param.js";
import { formatPh, formatName } from "@/utils/validate";
import {
  importStudent,
  queryEducationalsDetailByTaskId,
  importStudentAsyn,
  exportprocess,
} from "@/api/student";
import { queryPageButton } from "@/api/permission";
import axios from "axios";
import { getToken } from "@/utils/auth";
import SparkMD5 from "spark-md5";
import { minioUpload, merge } from "@/api/order";

const chunkSize = 5 * 1024 * 1024;
export default {
  name: "TaskMgt",
  data() {
    var res = /^\d+$|^\d+[.]?\d+$/;
    var tells = /^[1]([3-9])[0-9]{9}$/;
    const zuoji = /0\d{2,3}-\d{7,8}|\(?0\d{2,3}[)-]?\d{7,8}|\(?0\d{2,3}[)-]*\d{7,8}/;
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("任务代码不能为空"));
      } else {
        callback();
      }
    };
    const validateTitle = (rule, value, callback) => {
      if (!value) {
        callback(new Error("计划标题不能为空"));
      } else {
        callback();
      }
    };
    const validateSchoolId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("学校信息不能为空"));
      } else {
        callback();
      }
    };
    const validatePayType = (rule, value, callback) => {
      if (value !== 0 && value !== 1 && value !== 2) {
        callback(new Error("请选择支付类型"));
      } else {
        callback();
      }
    };
    const validateCollectType = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择采集类型"));
      } else {
        callback();
      }
    };
    const validateuUrgentType = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否加急"));
      } else {
        callback();
      }
    };
    const validateuIsRefund = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否自动退款"));
      } else {
        callback();
      }
    };
    const validateuIsReport = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否提供对比报告"));
      } else {
        callback();
      }
    };
    const validateuIsCjm = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否提供采集码"));
      } else {
        callback();
      }
    };

    const validateBeginDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集开始日期不能为空"));
      } else {
        callback();
      }
    };
    const validateEndDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("采集结束日期不能为空"));
      } else {
        callback();
      }
    };
    const validateShotPrice = (rule, value, callback) => {
      if (value != "0" && !value) {
        callback(new Error("收费标准不能为空"));
      } else {
        if (res.test(value) == false) {
          callback(new Error("格式不正确，只能输入数字"));
        } else {
          callback();
        }
      }
    };
    const validateStatus = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("状态不能为空"));
      } else {
        callback();
      }
    };
    const validateContactTell = (rule, value, callback) => {
      if (!value) {
        callback(new Error("联系电话不能为空"));
      } else {
        if (tells.test(value) == false) {
          if (zuoji.test(value) == false) {
            callback(new Error("请输入正确的手机或座机格式"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    const validateShotDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("拍摄日期不能为空"));
      } else {
        callback();
      }
    };

    const validateTimeBeginTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("开始时间不能为空"));
      } else {
        callback();
      }
    };
    const validateContactPerson = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人"));
      } else {
        callback();
      }
    };
    const validateSubscribeNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error("预约限制数量不能为空"));
      } else {
        callback();
      }
    };
    const validateTimeEndDate = (rule, value, callback) => {
      if (!value) {
        callback(new Error("联系人不能为空"));
      } else {
        callback();
      }
    };
    const validatePhotographer = (rule, value, callback) => {
      if (!value) {
        callback(new Error("摄影师不能为空"));
      } else {
        callback();
      }
    };
    const validateShotSubject = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择采集对象"));
      } else {
        callback();
      }
    };
    const validateMode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择打包模式"));
      } else {
        callback();
      }
    };
    const validateuIsAdult = (rule, value, callback) => {
      if (value !== 0 && value !== 1) {
        callback(new Error("请选择是否为成教/分院信息"));
      } else {
        callback();
      }
    };

    return {
      pageLoading: false,
      schoolName: "",
      shotSubject: "",
      isBatch: "",
      times: Date.now() * 1,
      searchTitle: "",

      searchStudentName: "",
      searchStudentIdNumber: "",
      searchStudentCode: "",
      redSearchOrderNo: "",
      redSearchName: "",
      redSearchIdNumber: "",
      taskId: 0,
      dialogType: 1,
      currentPage: 1,
      pageSize: 20,
      total: 0,
      // 退款分页
      currentPageRefund: 1,
      pageSizeRefund: 20,
      totalRefund: 0,
      refundNum: 0,
      RefundId: "",
      beginDate: null,
      endDate: null,
      searchCreateDate: null,
      searchCreateDateEnd: null,
      tableLoading: false,
      dlgDtlVisible: false,
      dialogVisible: false,
      dialogTimesVisible: false,
      refundLodShow: false,
      refundLoading: false,
      tableSelection: [],
      RefundSelection: [],
      taskTimeList: [],
      taskList: [],
      refundList: [],
      taskForm: {},
      taskDetailForm: {},
      taskRules: {
        code: [
          {
            required: true,
            trigger: "blur",
            validator: validateCode,
          },
        ],
        title: [
          {
            required: true,
            trigger: "blur",
            validator: validateTitle,
          },
        ],
        schoolId: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchoolId,
          },
        ],
        payType: [
          {
            required: true,
            trigger: "blur",
            validator: validatePayType,
          },
        ],
        appointmentType: [
          {
            required: true,
            trigger: "blur",
            validator: validateCollectType,
          },
        ],
        urgentType: [
          {
            required: true,
            trigger: "blur",
            validator: validateuUrgentType,
          },
        ],
        isRefund: [
          {
            required: true,
            trigger: "blur",
            validator: validateuIsRefund,
          },
        ],
        isReport: [
          {
            required: true,
            trigger: "blur",
            validator: validateuIsReport,
          },
        ],
        isCjm: [
          {
            required: true,
            trigger: "blur",
            validator: validateuIsCjm,
          },
        ],
        isAdult: [
          {
            required: true,
            trigger: "blur",
            validator: validateuIsAdult,
          },
        ],
        beginDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateBeginDate,
          },
        ],
        endDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateEndDate,
          },
        ],
        shotPrice: [
          {
            required: true,
            trigger: "blur",
            validator: validateShotPrice,
          },
        ],
        status: [
          {
            required: true,
            trigger: "blur",
            validator: validateStatus,
          },
        ],
        contactTell: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactTell,
          },
        ],
        contactPerson: [
          {
            required: true,
            trigger: "blur",
            validator: validateContactPerson,
          },
        ],
        photographer: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhotographer,
          },
        ],
        shotSubject: [
          {
            required: true,
            trigger: "blur",
            validator: validateShotSubject,
          },
        ],
      },
      taskDetailRules: {
        photographer: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhotographer,
          },
        ],
      },
      // dateRangeArray: [],
      editTimeType: 1,
      dlgTimeVisiable: false,
      taskTimeForm: {},
      taskTimeRules: {
        shotDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateShotDate,
          },
        ],
        beginDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateTimeBeginTime,
          },
        ],
        endDate: [
          {
            required: true,
            trigger: "blur",
            validator: validateTimeEndDate,
          },
        ],
        subscribeNum: [
          {
            required: true,
            trigger: "blur",
            validator: validateSubscribeNum,
          },
        ],
        isUploadInfo: false,
      },
      confirmLoading: false,
      confirmLoadingMake: false,
      confirmLoadingStudent: false,
      confirmDownLoading: false,
      confirmLoadingStu: false,
      confirmLoadingFace: false,
      packageLoading: false,
      finishedLoading: false,
      //学历
      educationTypeOptions: [
        {
          value: "成教",
          label: "成教",
        },
        {
          value: "全日制本专科",
          label: "全日制本专科",
        },
        {
          value: "全日制研究生",
          label: "全日制研究生",
        },
      ],
      schools: [],
      photographers: [],

      allSchools: [],
      loading: false,
      // 批量上传
      batchUplodShow: false,
      batchUpdateLoading: false,
      batchUploadId: null,
      // 上传采集码
      cjmUploadId: null,
      batchUplodTitle: "",
      // shotSubjectValue: 0,

      //批量导入
      msgVisible: false,
      importSatus: "info",
      importMsg: "",
      dlgImportVisible: false,
      fileForm: {
        taskId: "",
        file: null,
      },
      hasFile: false,
      fileList: [],

      //打包下载
      dlgDownloadVisible: false,
      downloadForm: {},
      downloadRules: {
        mode: [
          {
            required: true,
            trigger: "blur",
            validator: validateMode,
          },
        ],
      },

      //拍摄日期范围
      pickerOptions: {
        disabledDate: this.setShotDateRange,
      },
      //日历
      selectDate: new Date(),
      Calendar: [],
      form: {
        id: null,
        EventTitle: null,
        EventTime: null,
      },
      calendarEvents: [],
      dialogFormVisible: false,
      optTitle: "添加事件",
      newDate: new Date(),

      //学生情况
      studentList: [],
      dlgViewStudentVisible: false,
      studentCurrentPage: 1,
      studentPageSize: 10,
      studentTotal: 0,
      tableStudentLoading: false,

      //   查看导入记录
      ViewrecordVisible: false,
      ViewrecordLoading: false,
      ViewrecordList: [],

      adultId: "", //成教点任务ID
      UplodTitle: "上传学生信息", //上传学生信息-成教点标题
      isAdult: "", //是否导入学校数据

      ModeVisible: false, //打包字段弹窗
      ModeData: [], //选择的字段数据
      field: [], //传送接口的模式数据
      modeShow: null, //打包 0
      ModeOption: [
        {
          label: "加密姓名",
          value: "cjmXm",
        },
        {
          label: "非加密姓名",
          value: "studentName",
        },
        {
          label: "加密身份证号",
          value: "cjmZjhm",
        },
        {
          label: "非加密身份证号",
          value: "idNumber",
        },
        {
          label: "学校名称",
          value: "schoolName",
        },
        {
          label: "学号",
          value: "studentCode",
        },
        {
          label: "采集码",
          value: "cjmn",
        },
        {
          label: "分院",
          value: "branchCollege",
        },
        {
          label: "班级",
          value: "classes",
        },
        {
          label: "学历层次",
          value: "cc",
        },
        {
          label: "学历类别",
          value: "xllb",
        },
        {
          label: "考生号",
          value: "examineeNumber",
        },
        {
          label: "院校代码",
          value: "schoolCode",
        },
        {
          label: "毕业时间",
          value: "graduationYear",
        },
        {
          label: "成教/分院信息",
          value: "adultEducation",
        },
        {
          label: "专业",
          value: "major",
        },
        {
          label: "年级",
          value: "grade",
        },
        {
          label: "学校类别",
          value: "schoolTypeName",
        },
        {
          label: "性别",
          value: "sex",
        },
        {
          label: "摄影师",
          value: "collectedBy",
        },
      ],

      //按钮权限
      searchButton: false,
      addButton: false,
      updateButton: false,
      deleteButton: false,
      viewButton: false,
      pushReminderButton: false, //提醒推送
      taskTimeButton: false,
      photographerButton: false,
      downloadXpButton: false,
      downloadXpFailButton: false,
      enableButton: false,
      finishedButton: false,
      cencelButton: false,
      viewStudentButton: false,
      stuUplodShow: false,
      stuUpdateLoading: false,
      adultEducationButton: false,
      batchUploadButton: false,
      cjmUploadButton: false,
      ViewrecordButton: false,
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchools();
    this.queryParam();
  },
  methods: {
    queryButton() {
      const data = 3;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "add") {
            this.addButton = true;
          }
          if (button.code === "delete") {
            this.deleteButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "update") {
            this.updateButton = true;
          }
          if (button.code === "task_time") {
            this.taskTimeButton = true;
          }
          if (button.code === "photographer") {
            this.photographerButton = true;
          }
          if (button.code === "downloadXp") {
            this.downloadXpButton = true;
          }
          if (button.code === "downloadXpFail") {
            this.downloadXpFailButton = true;
          }
          if (button.code === "enable") {
            this.enableButton = true;
          }
          if (button.code === "finished") {
            this.finishedButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "viewStudent") {
            this.viewStudentButton = true;
          }
          if (button.code === "uploadStuInfo") {
            this.isUploadInfo = true;
          }
          if (button.code === "pushReminderButton") {
            this.pushReminderButton = true;
          }
          if (button.code === "adultEducationButton") {
            this.adultEducationButton = true;
          }
          if (button.code === "batchUploadButton") {
            this.batchUploadButton = true;
          }
          if (button.code === "cjmUploadButton") {
            this.cjmUploadButton = true;
          }
          if (button.code === "ViewrecordButton") {
            this.ViewrecordButton = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    queryParam() {
      queryParam().then((resp) => {
        let arrList = [];
        arrList = resp.data.photographer;
        let arr = arrList.split(",");
        let res = [];
        arr.forEach((item) => {
          res.push({ photographerName: item });
        });
        this.photographers = res;
        // console.log(this.photographers);
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    query() {
      const data = {
        title: this.searchTitle,
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
        schoolName: this.schoolName,
        shotSubject: this.shotSubject,
        isBatch: this.isBatch,
      };
      this.tableLoading = true;
      queryTask(this.currentPage, this.pageSize, data).then((resp) => {
        this.taskList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    //查看导入进度
    handleViewrecord(id) {
      this.ViewrecordList = [];
      this.ViewrecordVisible = true;
      this.ViewrecordLoading = true;
      uploadTaskList(id).then((res) => {
        if (res.code == 0) {
          this.ViewrecordList = res.data;
        }
        this.ViewrecordLoading = false;
      });
    },
    // 上传成教点
    adultEducation(id) {
      this.adultId = id;
      (this.UplodTitle = "上传成教/分院信息"), (this.stuUplodShow = true);
    },
    //采集提醒推送
    pushReminder(taskId) {
      this.$confirm("是否对该任务进行采集提醒推送？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        smsRemind(taskId)
          .then((res) => {
            if (res.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: res.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: res.message,
                type: "warning",
              });
            }
            this.query();
          })
          .catch((err) => {
            this.query();
            this.$notify({
              title: "警告",
              message: err,
              type: "error",
            });
          });
      });
    },
    //退款列表
    queryRefunds(id) {
      const data = {
        orderNo: this.redSearchOrderNo,
        realName: this.redSearchName,
        idNumber: this.redSearchIdNumber,
        taskId: id,
      };
      this.refundLoading = true;
      queryRefunds(this.currentPageRefund, this.pageSizeRefund, data).then((resp) => {
        if (resp.code == 0) {
          this.refundList = resp.data.content;
          this.totalRefund = resp.data.totalElements;
        }
      });
      this.refundLoading = false;
    },
    handleSelectionChange(val) {
      this.tableSelection = val;
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchTitle = "";
      this.searchCreateDate = null;
      this.searchCreateDateEnd = null;
      this.currentPage = 1;
      this.query();
    },
    setShotDateRange(time) {
      return (
        time.getTime() < new Date(this.beginDate).getTime() ||
        time.getTime() > new Date(this.endDate).getTime()
      );
    },

    //批量上传--弹窗打开
    batchUpload(batchUploadId) {
      this.batchUploadId = batchUploadId;
      this.batchUplodTitle = "批量上传";
      this.batchUplodShow = true;
    },
    // 上传采集码
    cjmUpload(cjmUploadId) {
      this.cjmUploadId = cjmUploadId;
      this.batchUplodTitle = "上传采集码";
      this.batchUplodShow = true;
    },

    //批量上传
    async handleUploadBatch(file) {
      this.batchUpdateLoading = true;
      if (file.raw) {
        let fileData = file.raw;
        const fileMd5 = await this.getFileMd5(fileData);
        let i = 0;
        this.PostFile(fileData, i, fileMd5);
      }
    },

    // 分片上传文件
    PostFile(file, i, fileMd5) {
      let fileName = file.name,
        fileType = file.type,
        fileSize = file.size,
        chunkCount = Math.ceil(fileSize / chunkSize); //总片数
      if (i >= chunkCount) {
        return;
      }
      let start = i * chunkSize;
      let end = start + chunkSize;
      let packet = file.slice(start, end); //将文件进行切片
      // console.log(packet);
      let form = new FormData();
      form.append("md5", fileMd5);
      form.append("file", packet); //slice方法用于切出文件的一部分
      form.append("name", fileName);
      form.append("totalSize", fileSize);
      form.append("total", chunkCount); //总片数
      form.append("index", i + 1); //当前是第几片
      minioUpload(form).then((resp) => {
        if (resp.data.status === 20001) {
          /*  表示上一块文件上传成功，继续下一次  */
          form = "";
          i++;
          this.PostFile(file, i, fileMd5);
        } else if (resp.data.status === 50000) {
          form = "";
          /*  失败后，每2秒继续传一次分片文件  */
          setInterval(function () {
            this.PostFile(file, i, fileMd5);
          }, 2000);
        } else if (resp.data.status === 20002) {
          this.merge(chunkCount, fileName, fileMd5, fileSize, fileType);
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        } else {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
          });
        }
      });
    },
    // 合并分片文件
    merge(chunkCount, fileName, fileMd5, fileSize, fileType) {
      let postData = {
        shardCount: chunkCount,
        md5: fileMd5,
        fileName: fileName,
        fileType: fileType,
        fileSize: fileSize,
      };
      merge(postData).then((resp) => {
        if (resp.code == 0) {
          let uploadData = {
            fileName: resp.data.fileName,
            taskId: this.batchUploadId || this.cjmUploadId,
            fileRealName: fileName,
          };
          // console.log(uploadData);
          let axiosurl = "";
          if (this.batchUplodTitle == "批量上传") {
            axiosurl = "/api/csias/sys/taskInfo/uploadFile";
          } else if (this.batchUplodTitle == "上传采集码") {
            axiosurl = "/api/csias/sys/taskInfo/uploadCjm";
          }
          // console.log(axiosurl);
          axios({
            url: axiosurl,
            method: "POST",
            dataType: "json",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getToken(),
            },
            data: uploadData,
            timeout: 180000,
          }).then((response) => {
            if (response.data.code == 0) {
              this.batchUplodShow = false;
              this.batchUpdateLoading = false;
              this.$notify({
                title: "温馨提示",
                message: response.data.message,
                type: "success",
              });
            } else {
              this.batchUplodShow = false;
              this.batchUpdateLoading = false;
              this.$notify({
                title: "警告",
                message: response.data.message,
                type: "warning",
              });
            }
          }),
            (err) => {
              this.batchUplodShow = false;
              this.batchUpdateLoading = false;
              this.$notify.error({
                title: "错误",
                message: response.message,
              });
            };
        } else {
          this.batchUplodShow = false;
          this.batchUpdateLoading = false;
          this.$notify({
            title: "警告",
            message: "未知错误",
            type: "warning",
          });
        }
      });
    },
    // md5文件转化
    getFileMd5(file, chunkCount) {
      return new Promise((resolve, reject) => {
        let blobSlice =
          File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        let chunks = chunkCount;
        let currentChunk = 0;
        let spark = new SparkMD5.ArrayBuffer();
        let fileReader = new FileReader();

        fileReader.onload = function (e) {
          spark.append(e.target.result);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
          } else {
            let md5 = spark.end();
            resolve(md5);
          }
        };
        fileReader.onerror = function (e) {
          reject(e);
        };
        function loadNext() {
          let start = currentChunk * chunkSize;
          let end = start + chunkSize;
          if (end > file.size) {
            end = file.size;
          }
          fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }
        loadNext();
      });
    },
    // 选择预约方式
    handleType() {
      this.taskForm.payType = 0;
      if (this.taskForm.appointmentType == 1) {
        this.taskForm.payType = 1;
      }
    },
    //选择打包模式
    handleMode() {
      if (this.modeShow == 1) {
        // 去除照片下载点击事件
        // this.ModeVisible = true;
        /**
         * 当选择模式发生变化时，清空字段
         */
        this.field = [];
        this.ModeData = [];
      }
    },
    //确定打包字段
    defineMode() {
      this.field = this.ModeData.join(",");
      if (this.field.length) {
        this.ModeVisible = false;
        const data = this.downloadForm;
        data.field = this.field;
        this.packageLoading = true;
        packageXp(data)
          .then((resp) => {
            this.$notify.success({
              title: "成功",
              message: "文件打包成功！",
            });
            this.downloadForm.mode = "";
            this.ModeData = [];
            this.field = [];
            this.ModeVisible = false;
            this.modeShow = null;
            this.packageLoading = false;
            this.dlgDownloadVisible = false;
          })
          .catch((err) => {
            this.downloadForm.mode = "";
            this.ModeData = [];
            this.field = [];
            this.ModeVisible = false;
            this.modeShow = null;
            this.packageLoading = false;
            // this.$notify.error({
            //   title: "错误",
            //   message: err,
            // });
          });
      }
      // console.log(this.field);
    },
    // 关闭选择字段弹窗
    closeMode() {
      this.ModeVisible = false;
      this.packageLoading = false;

      /**
       * 当选择字段数据为空时，清空选择模式，以便于修改字段时的数据回显效果,
       */
      if (!this.ModeData.length || !this.field.length) {
        this.downloadForm.mode = "";
        this.modeShow = null;
      }
    },

    // 选择采集对象
    handleSubject() {
      this.taskForm.isCjm = null;
      if (this.taskForm.shotSubject == 0) {
        this.taskForm.isCjm = 0;
      }
    },
    handleAdd() {
      this.taskForm = {
        id: 0,
        collectType: 0,
        shotSubject: 1,
        appointmentType: 1,
        urgentType: 0,
        isRefund: 1,
        isReport: 0,
        status: 0,
        importFile: null,
        contactPerson: "",
        contactTell: "",
        code: "",
        payType: 1, //支付类型
        isCjm: null,
      };
      if (this.$refs.taskForm !== undefined) {
        this.$refs.taskForm.resetFields();
      }
      this.confirmLoading = false;
      this.dialogType = 1;
      this.dialogVisible = true;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
      // this.getCode();
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的项不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }
          deleteTask(data)
            .then((resp) => {
              if (resp.code === "0") {
                this.$notify({
                  title: "删除成功",
                  message: resp.message,
                  type: "success",
                });
                this.currentPage = 1;
                this.query();
              } else {
                this.$notify({
                  title: "警告",
                  message: resp.message,
                  type: "warning",
                });
              }
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    add() {
      // console.log(this.taskForm.isCjm);
      let dataPar = this.fileForm;
      if (this.hasFile) {
        //限制文件大小
        const file = dataPar.file;
        const size = file.size / 1024 / 1024;
        if (size > 10) {
          this.$notify.warning({
            title: "警告",
            message: "大小必须小于10M",
          });
          return;
        }
      }
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = JSON.parse(JSON.stringify(this.taskForm));
          data.photographer = this.taskForm.photographer.join(",");
          data.educationType = this.taskForm.educationType.join(",");
          // this.taskForm.photographer = this.taskForm.photographer.join(",");
          // this.taskForm.educationType = this.taskForm.educationType.join(",");
          addTask(data)
            .then((resp) => {
              this.taskForm = resp.data;
              let msgTask = resp.message;
              if (this.hasFile) {
                //如果有文件，新增成功后导入学籍信息
                let fdParams = new FormData();
                fdParams.append("taskId", resp.data.id);
                fdParams.append("file", dataPar.file.raw);
                importStudent(fdParams).then((resp) => {
                  if (resp.data.code == 0) {
                    this.importMsg = resp.data.msg;
                    this.importSatus = "success";
                    this.dialogVisible = false;

                    this.$notify({
                      title: "导入数据成功！",
                      message: msgTask + "," + this.importMsg,
                      type: "success",
                    });
                  } else {
                    this.importMsg = resp.data.msg;
                    this.importSatus = "error";
                    this.msgVisible = true;

                    this.$notify({
                      title: "导入数据失败",
                      message: msgTask + "," + this.importMsg,
                      type: "error",
                    });
                  }

                  this.confirmLoading = false;
                  this.currentPage = 1;
                  this.query();
                });
              } else {
                this.$notify({
                  title: "新增任务成功",
                  message: resp.message,
                  type: "success",
                });

                this.confirmLoading = false;
                this.dialogVisible = false;
                this.taskId = this.taskForm.id;
                this.dialogType = 3;
                this.currentPage = 1;
                this.query();
              }
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 查看退款详情
    handleRefund(id) {
      this.RefundId = id;
      this.refundLodShow = true;
      this.queryRefunds(this.RefundId);
    },
    // 查看退款搜索
    handleSearchRed() {
      this.currentPageRefund = 1;
      this.queryRefunds(this.RefundId);
    },

    // 导出退款信息
    exportRefund() {
      const url = "/api/csias/sys/orderRefund/exportRefunds/" + this.RefundId;
      this.exportExcel(url);
    },

    // 退款选择
    handleSelectRefund(val) {
      this.RefundSelection = val;
      this.refundNum = val.length;
    },
    // 确认已退款
    refundConfirm() {
      const json = JSON.parse(JSON.stringify(this.RefundSelection));
      let ids = [];
      let postData = {};
      json.map((item) => {
        ids.push(item.id);
      });
      if (!ids.length) {
        this.$notify.warning({
          title: "警告",
          message: "选择任务不能为空",
        });
      } else {
        postData.ids = ids.join(",");
        comfirmRefunds(postData).then((resp) => {
          if (resp.code == 0) {
            this.$notify.success({
              title: "温馨提示",
              message: resp.message,
            });
          } else {
            this.$notify.warning({
              title: "警告",
              message: resp.messag,
            });
          }
          this.queryRefunds(this.RefundId);
        });
      }
    },
    handleView(id) {
      const data = id;
      if (this.$refs.taskForm !== undefined) {
        //重置表单信息
        this.$refs.taskForm.resetFields();
      }
      getTask(data)
        .then((resp) => {
          this.taskForm = resp.data;
          if (resp.data.isRefund === "0") {
            this.taskForm.isRefund = 0;
          } else if (resp.data.isRefund === "1") {
            this.taskForm.isRefund = 1;
          }
          if (resp.data.isReport === "0") {
            this.taskForm.isReport = 0;
          } else if (resp.data.isReport === "1") {
            this.taskForm.isReport = 1;
          }
          this.querySchools();
          this.dialogType = 2;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    //修改采集时间
    handleTimes(id) {
      const data = id;
      getTask(data)
        .then((resp) => {
          this.taskForm = resp.data;
          this.querySchools();
          this.dialogType = 2;
          this.dialogTimesVisible = true;
        })
        .catch((error) => {});
    },
    handleTaskGrapher(id) {
      const data = id;
      this.taskDetailForm = {};
      getTaskDetail(data)
        .then((resp) => {
          this.taskDetailForm = resp.data;
          this.dlgDtlVisible = true;
        })
        .catch((error) => {});
    },
    handleUpdate(id) {
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.confirmLoading = false;
      this.importSatus = "info";
      if (this.$refs.taskForm !== undefined) {
        //重置表单信息
        this.$refs.taskForm.resetFields();
      }
      const data = id;
      getTask(data)
        .then((resp) => {
          this.taskForm = resp.data;
          if (resp.data.photographer) {
            this.taskForm.photographer = resp.data.photographer.split(",");
          }
          this.querySchools();
          this.dialogType = 3;
          this.dialogVisible = true;
        })
        .catch((error) => {});
    },
    update() {
      let dataPar = this.fileForm;
      if (this.hasFile) {
        //限制文件大小
        const file = dataPar.file;
        const size = file.size / 1024 / 1024;
        if (size > 10) {
          this.$notify.warning({
            title: "警告",
            message: "大小必须小于10M",
          });
          return;
        }
      }
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.taskForm;
          data.photographer = this.taskForm.photographer.join(",");

          updateTask(data)
            .then((resp) => {
              let msgTask = resp.message;
              if (this.hasFile) {
                //如果有文件，新增成功后导入学籍信息
                let fdParams = new FormData();
                fdParams.append("taskId", resp.data.id);
                fdParams.append("file", dataPar.file.raw);
                importStudent(fdParams).then((resp) => {
                  if (resp.data.code == 0) {
                    this.importMsg = resp.data.msg;
                    this.importSatus = "success";
                    this.dialogVisible = false;

                    this.$notify({
                      title: "导入数据成功！",
                      message: msgTask + "," + this.importMsg,
                      type: "success",
                    });
                  } else {
                    this.importMsg = resp.data.msg;
                    this.importSatus = "error";
                    this.msgVisible = true;
                    this.$notify({
                      title: "导入数据失败",
                      message: this.importMsg,
                      type: "error",
                    });
                  }

                  this.confirmLoading = false;
                  this.currentPage = 1;
                  this.query();
                });
              } else {
                this.$notify({
                  title: "修改任务成功",
                  message: resp.message,
                  type: "success",
                });
                this.confirmLoading = false;
                this.dialogVisible = false;
                this.currentPage = 1;
                this.query();
              }
            })
            .catch((error) => {
              this.confirmLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    saveGrapher() {
      this.$refs.taskDetailForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          const data = this.taskDetailForm;
          savePhotographer(data)
            .then((resp) => {
              this.$notify({
                title: "分配摄影师成功",
                message: "分配摄影师成功",
                type: "success",
              });
              this.dlgDtlVisible = false;
              this.currentPage = 1;
            })
            .catch((error) => {});
        } else {
          return false;
        }
      });
    },
    handleAddTaskTime() {
      if (this.taskId === 0) {
        this.$notify.warning({
          title: "警告",
          message: "请先保存任务，再编辑时段！",
        });
        return;
      }
      this.taskTimeForm = {
        taskId: this.taskId,
        shotDate: this.beginDate,
      };
      this.taskTimeList.push(this.taskTimeForm);
      // this.editTimeType = 1;
    },
    handleDeleteTaskTime(index) {
      this.taskTimeList.splice(index, 1);
    },
    queryTaskTime(taskId) {
      //查询当前任务的时段
      if (taskId != 0) {
        getTaskPeriodById(taskId)
          .then((resp) => {
            this.taskTimeList = resp.data;
          })
          .catch((error) => {});
      } else {
        this.taskTimeList = [];
      }
      //所有时段统计数据
      getTaskStatistics()
        .then((resp) => {
          this.Calendar = resp.data;
          this.editTimeType = 1;
          this.dlgTimeVisiable = true;
        })
        .catch((error) => {});
    },
    handleTaskTime(id, beginDate, endDate) {
      const data = id;
      this.taskId = id;
      this.beginDate = beginDate;
      this.endDate = endDate;
      this.queryTaskTime(data);
      // //初始化日历,限制开始结束日期
      // this.dateRangeArray = [];
      // getTask(data)
      //     .then((resp) => {
      //         let taskData = resp.data;
      //         //限制日期的起止范围,(必须为周一到周日)
      //         this.dateRangeArray.push(dateFormat("YYYY-mm-dd", new Date(taskData.beginDateMonday)));
      //         this.dateRangeArray.push(dateFormat("YYYY-mm-dd", new Date(taskData.endDateSunday)));
      //         this.queryTaskTime(data);
      //         if (this.$refs.taskTimeForm !== undefined) {
      //             this.$refs.taskTimeForm.resetFields();
      //         }
      //     })
      //     .catch((error) => {
      //     });
    },
    handleDownloadXp(data) {
      this.taskId = data.id;
      this.isAdult = data.isAdult;
      this.downloadForm = {
        taskId: data.id,
        rarFile1: "",
        // rarFile2: "",
        rarFile3: "",
        rarFile4: "",
      };
      if (this.$refs.downloadForm !== undefined) {
        this.$refs.downloadForm.resetFields();
      }
      getTask(this.taskId)
        .then((resp) => {
          let taskData = resp.data;
          this.downloadForm.rarFile1 = taskData.rarFile1;
          // this.downloadForm.rarFile2 = taskData.rarFile2;
          // this.downloadForm.rarFile3 = taskData.rarFile3;
          this.downloadForm.rarFile4 = taskData.rarFile4;
        })
        .catch((error) => {});
      this.confirmLoading = false;
      this.dlgDownloadVisible = true;
    },
    handleViewStudent(id) {
      this.taskId = id;
      this.searchStudentName = "";
      this.searchStudentIdNumber = "";
      this.searchStudentCode = "";
      this.studentList = [];
      this.dlgViewStudentVisible = true;
      if (this.$refs.dlgViewStudentVisible !== undefined) {
        this.$refs.dlgViewStudentVisible.resetFields();
      }
      this.studentCurrentPage = 1;
      this.queryStudent();
    },
    /**
     * 弹出上传学生
     */
    handleViewStuUpload(taskId) {
      (this.UplodTitle = "上传学生信息"), (this.taskId = taskId);
      this.stuUplodShow = true;
    },
    handleSearchStudent() {
      this.studentCurrentPage = 1;
      this.queryStudent();
    },
    queryStudent() {
      this.tableStudentLoading = true;
      const data = {
        taskId: this.taskId,
        name: this.searchStudentName,
        idNumber: this.searchStudentIdNumber,
        studentCode: this.searchStudentCode,
      };
      queryEducationalsDetailByTaskId(
        this.studentCurrentPage,
        this.studentPageSize,
        data
      ).then((resp) => {
        this.studentList = resp.data.content;
        this.studentTotal = resp.data.totalElements;
        this.tableStudentLoading = false;
      });
    },
    handlePackage() {
      this.modeShow = 1;

      this.$refs.downloadForm.validate((valid) => {
        if (valid) {
          getTask(this.taskId)
            .then((resp) => {
              let taskData = resp.data;
              let filePath = null;
              if (this.downloadForm.mode === 1) {
                filePath = taskData.rarFile1;
              }
              // else if (this.downloadForm.mode === 2) {
              //   filePath = taskData.rarFile2;
              // }
              else if (this.downloadForm.mode === 3) {
                filePath = taskData.rarFile3;
              } else if (this.downloadForm.mode === 4) {
                filePath = taskData.rarFile4;
              }
              if (filePath != null && filePath != "") {
                this.$confirm(
                  "已存在打包文件, 重新打包将覆盖上一次的打包文件，是否继续?",
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.ModeVisible = true;
                    this.defineMode();
                  })
                  .catch(() => {});
              } else {
                this.ModeVisible = true;
                this.defineMode();
              }
            })
            .catch((error) => {});
        }
      });
    },

    //打包文件下载
    handleBgeinDownload() {
      this.$refs.downloadForm.validate((valid) => {
        if (valid) {
          this.confirmDownLoading = true;
          const data = this.downloadForm;
          const url =
            //window._config["baseUrl"] +
            "/api/csias/file/downloadXpNew?taskId=" +
            this.downloadForm.taskId +
            "&mode=" +
            this.downloadForm.mode;
          axios({
            method: "GET",
            url: url,
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
            },
            //responseType: "arraybuffer",
            timeout: 3000000,
          }).then(
            (response) => {
              if (response.status === 200 && response.data && response.data.code == 0) {
                let fileName = "校拍图片" + Date.parse(new Date()) + ".rar";
                var link = document.createElement("a");
                link.href = response.data.data + "&filename=" + fileName;
                link.download = fileName;
                link.target = "_blank";
                link.click();
                //释放内存
                window.URL.revokeObjectURL(link.href);
                this.$notify.success({
                  title: "成功",
                  message: "下载文件成功！",
                });
              } else {
                this.$notify.error({
                  title: "错误",
                  message: "下载失败，打包文件不存在",
                });
              }
              this.confirmDownLoading = false;
            },
            (err) => {
              this.confirmDownLoading = false;
              this.$notify.error({
                title: "错误",
                message: "下载失败，打包文件不存在",
              });
            }
          );
        }
      });
    },

    handleDownloadXpFail(id) {
      // window._config["baseUrl"] +
      const url = "/api/csias/file/downloadXpAsynFail/" + id;
      this.exportExcel(url);
    },
    handleExportNotPayXp() {
      //window._config["baseUrl"] +
      const url = "/api/csias/file/exportNotPayXp/" + this.taskId;
      this.confirmLoading = true;

      this.exportExcel(url);
    },
    //导出未有采集码
    handleExportCjm() {
      const url = "/api/csias/file/exportNotCjmXp/" + this.taskId;
      this.confirmLoadingStu = true;

      this.exportExcel(url);
    },
    handleExportNotMake() {
      // window._config["baseUrl"] +
      const url = "/api/csias/file/exportNotAppointXp/" + this.taskId;
      this.exportExcel(url);
    },
    //导出已经完成学生信息
    handleExportStudent() {
      // window._config["baseUrl"] +
      const url = "/api/csias/file/exportCompleteXp/" + this.taskId;
      this.exportExcel(url);
    },
    handleExportFace() {
      const url = "/api/csias/file/exportFaceError/" + this.taskId;
      this.exportExcel(url);
    },
    exportExcel(url) {
      this.tableLoading = true;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            console.log(response.data);
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.confirmLoading = false;
          this.confirmLoadingStu = false;
          this.tableLoading = false;
        },
        (err) => {
          this.confirmLoading = false;
          this.confirmLoadingStu = false;
          this.tableLoading = false;

          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },

    handleEnable(id) {
      this.$confirm("是否确认开始该任务预约?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setTaskStatus(id, 1)
            .then((resp) => {
              this.$notify({
                title: "开始预约成功",
                message: resp.message,
                type: "success",
              });
              this.finishedLoading = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleFinished(id) {
      this.$confirm("是否确认结束该任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.finishedLoading = true;
          setTaskStatus(id, 2)
            .then((resp) => {
              this.$notify({
                title: "结束任务成功",
                message: resp.message,
                type: "success",
              });
              this.finishedLoading = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleCencel(id) {
      this.$confirm("是否确认取消该任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.finishedLoading = true;
          setTaskStatus(id, 3)
            .then((resp) => {
              this.$notify({
                title: "取消任务成功",
                message: resp.message,
                type: "success",
              });
              this.finishedLoading = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleConfirm() {
      if (this.dialogType === 1) {
        this.add();
      } else if (this.dialogType === 3) {
        this.update();
      }
    },
    /**
     * 修改采集时间段
     */
    handleTimesConfirm() {
      this.$refs.taskForm.validate((valid) => {
        if (valid) {
          this.tableLoading = true;
          const data = this.taskForm;
          let postData = {
            id: data.id,
            beginDate: data.beginDate,
            endDate: data.endDate,
          };
          updateTaskCjTime(postData)
            .then((resp) => {
              this.$notify({
                title: "修改采集时间",
                message: resp.message,
                type: "success",
              });
              this.tableLoading = false;

              this.dialogTimesVisible = false;
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {
              this.tableLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleSaveTime() {
      if (this.taskId === 0) {
        this.$notify.warning({
          title: "警告",
          message: "请先保存任务，再编辑时段！",
        });
        return;
      }

      //校验数据
      for (const [index, item] of this.taskTimeList.entries()) {
        const rowIndex = index + 1;
        if (!item.shotDate) {
          this.$notify.warning({
            title: "警告",
            message: "第行" + rowIndex + "，拍摄日期不能为空！",
          });
          return;
        }
        if (!item.beginTime) {
          this.$notify.warning({
            title: "警告",
            message: "第行" + rowIndex + "，开始时间不能为空！",
          });
          return;
        }
        if (!item.endTime) {
          this.$notify.warning({
            title: "警告",
            message: "第行" + rowIndex + "，结束时间不能为空！",
          });
          return;
        }
        if (!item.subscribeNum) {
          this.$notify.warning({
            title: "警告",
            message: "第行" + rowIndex + "，预约限制数量不能为空！",
          });
          return;
        }
      }
      //批量保存时段
      batchSave(this.taskId, this.taskTimeList).then((resp) => {
        // this.queryTaskTime(this.taskId);
        this.query();
        this.tableLoading = false;

        this.dlgTimeVisiable = false;
        this.$notify({
          title: "保存时段成功",
          message: resp.message,
          type: "success",
        });
      });
    },
    //选择学校的时候带出学校联系人和联系电话
    selectSchool(schoolId) {
      getSchool(schoolId).then((resp) => {
        this.taskForm.contactPerson = resp.data.contactPerson;
        this.taskForm.contactTell = resp.data.contactTell;
      });
    },
    getCode() {
      getTaskCode().then((resp) => {
        this.taskForm.code = resp.data;
        console.log(this.taskForm.code);
      });
    },
    selectCalendar(selectDate) {
      this.selectDate = selectDate;
    },
    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.fileForm.file = null;
    },
    handlePreview(file) {
      console.log(file);
    },
    /**
     * 上传学生信息及成教点
     */
    handleUploadChange(file) {
      let fdParams = new FormData();
      fdParams.append("file", file.raw);
      this.stuUpdateLoading = true;
      if (this.UplodTitle == "上传成教/分院信息") {
        uploadAdult(this.adultId, fdParams).then((res) => {
          {
            if (res.code == 0) {
              this.$notify({
                title: "温馨提示",
                message: res.message,
                type: "success",
              });
            } else {
              this.$notify({
                title: "警告",
                message: res.message,
                type: "warning",
              });
              this.stuUplodShow = false;
              this.stuUpdateLoading = false;
            }
            this.stuUplodShow = false;
            this.stuUpdateLoading = false;
          }
        });
      } else {
        // console.log(fdParams);
        importStudentAsyn(this.taskId, fdParams).then((res) => {
          if (res.data.code == 0) {
            let json = JSON.parse(res.data.data);
            this.taskExportprocess(json.taskNo);
          } else {
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning",
            });
            this.stuUplodShow = false;
            this.stuUpdateLoading = false;
          }
        });
      }
    },
    /**
     * 查询任务进度
     */
    taskExportprocess(taskNo = "") {
      let that = this;
      exportprocess(taskNo).then((res) => {
        if (res.data.state == 0) {
          setTimeout(() => {
            that.taskExportprocess(taskNo);
          }, 500);
        } else if (res.data.state == 1) {
          that.$notify({
            title: "成功",
            message: "上传学生信息成功",
            type: "success",
          });
          that.stuUpdateLoading = false;
          that.stuUplodShow = false;
          this.queryLIst();
        } else {
          this.$notify({
            title: "警告",
            message: res.data.msg[0],
            type: "warning",
          });
          // this.stuUplodShow = false;
          this.stuUpdateLoading = false;
          this.queryLIst();
        }
      });
    },
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.fileForm.file = file;
    },
    //列表分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },

    // 退款列表分页
    handleSizeChangerefund(val) {
      this.pageSizeRefund = val;
      this.queryRefunds();
    },
    handleCurrentChangerefund(val) {
      this.currentPageRefund = val;
      this.queryRefunds();
    },
    // 学生信息分页
    handleStudentSizeChange(val) {
      this.studentPageSize = val;
      this.queryStudent();
    },
    handleStudentCurrentChange(val) {
      this.studentCurrentPage = val;
      this.queryStudent();
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    columnTimeFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("HH时MM分", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style>
.tip {
  background-color: #f9f9f9;
  border-radius: 4px;
  margin: 5px 0;
  line-height: 50px;
  font-weight: bolder;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 50px;
  line-height: 50px;
  vertical-align: bottom;
}

.gray {
  background-color: #f2f2f2;
}

.diviceRow {
  width: 1200px;
}

.diviceRow .col-1eft {
  float: left;
  width: 600px;
  height: 260px;
}

.diviceRow .col-right {
  float: left;
  display: flex;
  width: 555px;
  height: 250px;
}

.calendar-day {
  text-align: center;
  color: #202535;
  line-height: 30px;
  font-size: 12px;
}

.el-calendar-table .el-calendar-day {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px;
  height: 150px;
}

.el-calendar-table .el-calendar-click {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px;
  height: 100px;
}

.el-calendar-table thead th {
  padding: 6px 0;
  color: #606266;
  font-weight: normal;
  text-align: center;
}

.el-calendar-table:not(.is-range) td.next,
.el-calendar-table:not(.is-range) td.prev {
  opacity: 0.7;
}

.fc-day-grid-event {
  margin: 0;
  padding: 0;
  /*border: solid 1px blue;*/
}

.day-value {
  margin-top: 1px;
  margin-bottom: 1px;
  font-size: 12px;
}

#full_subscribe .spanValue {
  /*border: 1px solid #F5DEB3;*/
  /*background-color: #E6A23C;*/
  font-weight: bolder;
  color: #f56c6c;
}

#surplus_subcribe .spanValue {
  /*border: 1px solid #C1FFC1;*/
  /*background-color: #67C23A;*/
  font-weight: bolder;
  color: #67c23a;
}

.fc-day-grid-event:hover .day-button {
  visibility: initial;
}

.dayTitle {
  visibility: hidden;
}

.el-calendar-day:hover .dayTitle {
  visibility: initial;
}

.el-calendar-table .el-calendar-day {
  height: 70px;
}

.is-selected {
  color: #1989fa;
}
</style>
